/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

/* --- REUSABLE ELEMENTS --- */

$text-input-md-show-focus-highlight: true !default;
$text-input-md-show-valid-highlight: false !default;
$text-input-md-show-invalid-highlight: false !default;
$text-input-md-highlight-color: var(--ion-color-marine);
$alert-md-input-border-color-focused: var(--ion-color-marine);
$alert-md-button-text-color: var(--ion-color-marine);
$loading-md-spinner-color: var(--ion-color-marine);

* {
  font-variant-ligatures: none !important;
  font-family: 'Fira Sans', Arial, sans-serif !important;
}

.action-sheet-button {
  font-size: 16px !important;
}

ion-thumbnail {
  margin-right: 0 !important;
}

ion-label {
  white-space: normal !important;
  line-height: 22px;
}
ion-item {
  --highlight-color-valid: transparent;
  --highlight-color-invalid: transparent;
}

ion-spinner {
  width: 40px !important;
  height: 40px !important;
  --color: var(--ion-color-red);
}

hr {
  background: var(--ion-color-offwhite);
}
.button-text.sc-ion-back-button-md {
  position: relative !important;
  top: 2px !important;
}

.chat-dot {
  position: absolute;
  z-index: 3;
  right: 10px;
  top: 10px;
  background: var(--ion-color-chatblue);
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

/* general use */

@media only screen and (max-width: 560px) {
  .SC-support-footer {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.SC-support-footer {
  left: 0;
  right: 0;
  bottom: 0;
  border-top: 1px solid var(--ion-color-offwhite);
  width: 100%;
  padding: 26px 76px;
  background: var(--ion-color-superoffwhite);

  .support-button {
    display: inline-block;
    cursor: pointer;
    color: var(--ion-color-grey);
    font-size: 14px;
    margin-right: 48px;
  }
  .support-button:hover {
    color: var(--ion-color-red);
  }
}

.SC-fixed-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.SC-clickable {
  cursor: pointer;
}

.SC-list-spacer {
  background: var(--ion-color-offwhite);
  border-top: 1px solid var(--ion-color-lightgrey);
  border-bottom: 1px solid var(--ion-color-lightgrey);
  height: 18px;
}

.SC-truncate {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.SC-hr-margined {
  margin-left: 16px;
}

.SC-searchbar {
  background: var(--ion-color-marine);
  padding-top: 12px !important;

  .searchbar-search-icon {
    display: none !important;
  }

  .searchbar-input-container {
    background: var(--ion-color-marine);

    input {
      font-size: 16px !important;
      background: var(--ion-color-marine) !important;
      color: white !important;
      padding: 0 52px 0 12px !important;
    }
  }
}

.SC-caps {
  text-transform: uppercase;

  input {
    text-transform: uppercase;
  }
}

.SCWEB-menu-icon {
  cursor: pointer;
  position: absolute;
  top: 30px;
  left: 32px;

  ion-button {
    --box-shadow: none;
    padding: 0;
    height: 36px;
  }

  .SCWEB-menu-img {
    width: 36px;
  }
}

.SCWEB-address-container {
  position: absolute;
  right: 28px;
  top: 40px;
  color: var(--ion-color-marine);
  cursor: pointer;

  ion-icon {
    margin-left: 6px;
    font-size: 13px;
  }
  p {
    font-size: 14px;
    display: inline;
    position: relative;
    top: -2px;
  }
}
.SCWEB-address-container:hover {
  color: var(--ion-color-red);
}

.SCWEB-popover {
  --min-width: 320px;
  border-radius: 6px;
  /*--box-shadow: 0 1px 10px 0 rgba(183, 183, 183, 0.5);*/
  border: 0 1px 10px 0 rgba(183, 183, 183, 0.5);
}

.SCWEB-popover-services {
  --min-width: 523px;
  --max-height: 439px;
}

.SCWEB-popover-rooms {
  --min-width: 510px;
  --max-height: 600px;
  --min-height: 520px;

  .popover-content {
    bottom: 85px !important;
    right: 15px !important;
    top: auto !important;
    left: auto !important;
    background: var(--ion-color-superoffwhite);
  }
}

@media only screen and (max-height: 700px) {
  .SCWEB-popover-rooms {
    --max-height: 480px !important;
  }
}
@media only screen and (max-height: 580px) {
  .SCWEB-popover-rooms {
    --max-height: 425px !important;
  }
}

/* STRIPE CSS */

.StripeElement {
  margin-top: 12px;
  border: 1px solid var(--ion-color-lightgrey);
  margin-bottom: 0;
  padding-left: 16px;
  padding-right: 16px;
}
.StripeElement:focus {
  border: 1px solid var(--ion-color-red);
}
#card-errors {
  margin-top: 8px;
  font-size: 14px;
  color: var(--ion-color-red);
}

/* text */

.SC-description-text {
  text-align: center;
}

/* images */

.SC-menu-button-thumbnail {
  border-radius: 50%;
  width: 28px;
  height: 28px;
  background: white;
  padding: 1px;
}

.SC-round-thumb {
  border-radius: 50%;
  background: white;
  padding: 1px;
}

.SC-star-icon {
  color: var(--ion-color-gold);
  font-size: 14px;
  position: relative;
  top: 2px;
}

.SC-chevron-icon {
  color: var(--ion-color-marine);
  font-size: 12px;
}

.SC-edit-icon {
  font-size: 20px !important;
  line-height: 24px !important;
}

/* buttons */

.SC-long-button {
  margin: 32px 12px;
  width: calc(100% - 24px);
  height: 45px;
  text-transform: none;
  border-radius: 2px;
  font-weight: bold;
}

.SC-light-bottom-button {
  color: var(--ion-color-grey);
  text-align: center;
  font-size: 12px;
  width: 100%;
  margin-bottom: 25px;

  span {
    font-weight: bold;
    cursor: pointer;
  }
}

.SC-bottom-button-row {
  text-align: center;
  width: 80%;
  margin-left: 10%;
}

.SC-bottom-fixed-button {
  position: absolute;
  bottom: 16px;
  margin-bottom: 0 !important;
}

/* colors */

.SC-red {
  color: var(--ion-color-red);
}

.toast-message {
  font-size: 14px !important;
}

ion-toast {
  --button-color: white;
}

/* floating buttons */

$floating-button-height: 79px;
$button-margin: 12px;

.SC-content-above-floating-buttons {
  height: -webkit-calc(100% - #{$floating-button-height});
  height: -moz-calc(100% - #{$floating-button-height});
  height: calc(100% - #{$floating-button-height});
}

.SC-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  border-top: 1px solid var(--ion-color-lightgrey);
  background: white;
  z-index: 1000;
}

.SC-floating-buttons {
  /*position: fixed;*/
  /*bottom: 0;*/
  width: 100%;
  text-align: center;
  height: $floating-button-height;
  border-top: 1px solid var(--ion-color-lightgrey);
  background: white;
  z-index: 1000;
}

.SC-floating-buttons-less-tall {
  height: 68px;
}

.SC-floating-button {
  width: calc(50% - #{$button-margin} * 1.5);
  height: 55px;
  margin-top: 12px;
  box-shadow: 0px 2px 5px rgba(132, 132, 132, 0.35);
  border-radius: 3px;
  white-space: normal;
  font-weight: bold;
  cursor: pointer;
}

.SC-floating-button-wide {
  width: calc(100% - 2 * #{$button-margin});
}

.SC-floating-button-less-tall {
  height: 44px;
}

.SC-floating-small {
  width: calc(40% - #{$button-margin} * 1.5);
  font-size: 14px;
}

.SC-floating-large {
  width: calc(60% - #{$button-margin} * 1.5);
  font-size: 14px;
}

/* empty state */

.SC-empty-state-div {
  position: absolute;
  margin-left: 10%;
  margin-right: 10%;
  width: 80%;
  top: 36%;
  text-align: center;

  .SC-empty-state-icon {
    font-size: 34px;
    color: var(--ion-color-grey);
  }

  .SC-empty-state-spinner {
    svg circle {
      stroke: var(--ion-color-grey) !important;
    }
  }

  .SC-empty-state-text {
    margin-top: 20px;
    font-size: 16px;
    line-height: 24px;
    color: var(--ion-color-grey);
  }

  .SC-empty-state-action {
    margin-top: 24px;
    font-weight: bold;
    font-size: 12px;
    color: var(--ion-color-marine);
  }
}

/* dividers */

.SC-or-horizontal-rule {
  background: var(--ion-color-lightgrey);
  text-align: center;
  margin: 12px 12px 25px 12px;
  overflow: visible;
}

.SC-or-horizontal-rule:after {
  content: 'or';
  display: inline-block;
  position: relative;
  top: -13px;
  padding: 0 16px;
  background: white;
  color: var(--ion-color-grey);
  font-size: 14px;
  font-style: italic;
}

/* ionic element overrides */

ion-scroll[scrollX] {
  white-space: nowrap;

  ion-card {
    display: inline-block;
    border: 1px inside var(--ion-color-lightgrey);
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
  }

  ion-img {
    display: inline-block;
    border-radius: 3px;
    border: 1px inside var(--ion-color-lightgrey);
  }
}

.toolbar-content ion-title .toolbar-title {
  /*padding-left: 0;*/
  font-size: 20px;
}

.action-sheet-destructive {
  color: var(--ion-color-red) !important;
}

.swiper-pagination-bullet-active {
  background-color: var(--ion-color-marine);
}

.input-has-focus .label-md[floating] {
  color: var(--ion-color-marine) !important;
}

.item-md.item-input.input-has-focus .item-inner {
  border-bottom-width: 0px !important;
}

form .label-md {
  color: var(--ion-color-pGrey) !important;
}

.label-md p {
  color: var(--ion-color-pGrey);
  margin-top: 1px;
}

.item-md ion-icon[item-left] + .item-inner,
.item-md ion-icon[item-left] + .item-input,
.item-md ion-icon[slot='start'] + .item-inner,
.item-md ion-icon[slot='start'] + .item-input {
  margin-left: 16px;
}

.alert-tappable.alert-radio {
  height: auto;
  contain: content;
}
.alert-radio-label,
.alert-radio-label.sc-ion-alert-md,
.alert-radio-label.sc-ion-alert-ios {
  white-space: normal;
}

ion-item {
  --highlight-color-valid: transparent;
  --highlight-color-invalid: transparent;
}

ion-label {
  white-space: normal !important;
}

.action-sheet-title.sc-ion-action-sheet-md {
  height: auto !important;
}

hr {
  height: 1px;
  background: var(--ion-color-lightgrey);
}

ion-modal {
  --max-height: 96% !important;
  --height: 600px !important;
  --width: 450px !important;

  &.enter-phone-number-modal,
  &.enter-verification-code-modal {
    --height: 470px !important;
  }

  &.SCWEB-services-modal {
    --width: 700px !important;
    --height: 600px !important;
  }

  &.appt-review-modal {
    --width: 530px !important;
    --height: 640px !important;
  }

  &.special-instructions-modal {
    --height: 240px !important;
  }

  &.SCWEB-additional-tip-modal {
    --height: 340px !important;
  }

  &.auto-height {
    &.bottom {
      align-items: flex-end;
    }

    --height: auto !important;

    .ion-page {
      position: relative;
      display: block;
      contain: content;

      .inner-content {
        max-height: 80vh;
        overflow: auto;
        padding: 0 40px;
      }
    }
  }

  .ion-page {
    border-top: 2px solid var(--ion-color-marine);
    border-right: 2px solid var(--ion-color-marine);
  }

  ion-content,
  .inner-content {
    --padding-start: 40px;
    --padding-end: 40px;
    --ion-background-color: white;

    .SCWEB-header-text {
      font-family: 'Fira Sans-Bold' !important;
      font-size: 30px;
      color: var(--ion-color-darktext);
      text-align: center;
      line-height: 36px;
      margin-bottom: 0;
      margin-top: 20px;
    }

    .logo {
      margin-top: 40px;
      text-align: center;

      img {
        max-width: 40px;
      }
    }

    #description-text {
      text-align: center;
      margin-top: 20px;
      margin-bottom: 30px;
      color: var(--ion-color-grey);
      font-size: 15px;
    }

    .SC-long-button {
      width: 100%;
      height: 52px;
      margin: auto;
      margin-bottom: 15px;
    }
  }
}
