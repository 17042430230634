// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$app-primary: mat-palette($mat-indigo);
$app-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$app-warn: mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$app-theme: mat-light-theme($app-primary, $app-accent, $app-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($app-theme);

// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #003c5e;
  --ion-color-primary-rgb: 0, 60, 94;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #003553;
  --ion-color-primary-tint: #1a506e;

  /** secondary **/
  --ion-color-secondary: #0cd1e8;
  --ion-color-secondary-rgb: 12, 209, 232;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #0bb8cc;
  --ion-color-secondary-tint: #24d6ea;

  /** tertiary **/
  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;

  /** success **/
  --ion-color-success: #32db64;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: #fedc64;
  --ion-color-warning-rgb: 254, 220, 100;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0c258;
  --ion-color-warning-tint: #fee074;

  /** danger **/
  --ion-color-danger: #ff5050;
  --ion-color-danger-rgb: 255, 80, 80;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #e04646;
  --ion-color-danger-tint: #ff6262;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

:root {
  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-shade: #e0e0e0;
  --ion-color-white-tint: #ffffff;
}

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}

:root {
  --ion-color-black: #000000;
  --ion-color-black-rgb: 0, 0, 0;
  --ion-color-black-contrast: #ffffff;
  --ion-color-black-contrast-rgb: 255, 255, 255;
  --ion-color-black-shade: #000000;
  --ion-color-black-tint: #1a1a1a;
}

.ion-color-black {
  --ion-color-base: var(--ion-color-black);
  --ion-color-base-rgb: var(--ion-color-black-rgb);
  --ion-color-contrast: var(--ion-color-black-contrast);
  --ion-color-contrast-rgb: var(--ion-color-black-contrast-rgb);
  --ion-color-shade: var(--ion-color-black-shade);
  --ion-color-tint: var(--ion-color-black-tint);
}

:root {
  --ion-color-red: #ff5050;
  --ion-color-red-rgb: 255, 80, 80;
  --ion-color-red-contrast: #ffffff;
  --ion-color-red-contrast-rgb: 255, 255, 255;
  --ion-color-red-shade: #e04646;
  --ion-color-red-tint: #ff6262;
}

.ion-color-red {
  --ion-color-base: var(--ion-color-red);
  --ion-color-base-rgb: var(--ion-color-red-rgb);
  --ion-color-contrast: var(--ion-color-red-contrast);
  --ion-color-contrast-rgb: var(--ion-color-red-contrast-rgb);
  --ion-color-shade: var(--ion-color-red-shade);
  --ion-color-tint: var(--ion-color-red-tint);
}

:root {
  --ion-color-chatblue: #018ea2;
  --ion-color-chatblue-rgb: 1, 142, 162;
  --ion-color-chatblue-contrast: #ffffff;
  --ion-color-chatblue-contrast-rgb: 255, 255, 255;
  --ion-color-chatblue-shade: #017d8f;
  --ion-color-chatblue-tint: #1a99ab;
}

.ion-color-chatblue {
  --ion-color-base: var(--ion-color-chatblue);
  --ion-color-base-rgb: var(--ion-color-chatblue-rgb);
  --ion-color-contrast: var(--ion-color-chatblue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-chatblue-contrast-rgb);
  --ion-color-shade: var(--ion-color-chatblue-shade);
  --ion-color-tint: var(--ion-color-chatblue-tint);
}

:root {
  --ion-color-green: #00b177;
  --ion-color-green-rgb: 0, 177, 119;
  --ion-color-green-contrast: #ffffff;
  --ion-color-green-contrast-rgb: 255, 255, 255;
  --ion-color-green-shade: #009c69;
  --ion-color-green-tint: #1ab985;
}

.ion-color-green {
  --ion-color-base: var(--ion-color-green);
  --ion-color-base-rgb: var(--ion-color-green-rgb);
  --ion-color-contrast: var(--ion-color-green-contrast);
  --ion-color-contrast-rgb: var(--ion-color-green-contrast-rgb);
  --ion-color-shade: var(--ion-color-green-shade);
  --ion-color-tint: var(--ion-color-green-tint);
}

:root {
  --ion-color-grey: #86878e;
  --ion-color-grey-rgb: 134, 135, 142;
  --ion-color-grey-contrast: #000000;
  --ion-color-grey-contrast-rgb: 0, 0, 0;
  --ion-color-grey-shade: #76777d;
  --ion-color-grey-tint: #929399;
}

.ion-color-grey {
  --ion-color-base: var(--ion-color-grey);
  --ion-color-base-rgb: var(--ion-color-grey-rgb);
  --ion-color-contrast: var(--ion-color-grey-contrast);
  --ion-color-contrast-rgb: var(--ion-color-grey-contrast-rgb);
  --ion-color-shade: var(--ion-color-grey-shade);
  --ion-color-tint: var(--ion-color-grey-tint);
}

:root {
  --ion-color-pgrey: #999999;
  --ion-color-pgrey-rgb: 153, 153, 153;
  --ion-color-pgrey-contrast: #000000;
  --ion-color-pgrey-contrast-rgb: 0, 0, 0;
  --ion-color-pgrey-shade: #878787;
  --ion-color-pgrey-tint: #a3a3a3;
}

.ion-color-pgrey {
  --ion-color-base: var(--ion-color-pgrey);
  --ion-color-base-rgb: var(--ion-color-pgrey-rgb);
  --ion-color-contrast: var(--ion-color-pgrey-contrast);
  --ion-color-contrast-rgb: var(--ion-color-pgrey-contrast-rgb);
  --ion-color-shade: var(--ion-color-pgrey-shade);
  --ion-color-tint: var(--ion-color-pgrey-tint);
}

:root {
  --ion-color-lightgrey: #e1e3e5;
  --ion-color-lightgrey-rgb: 225, 227, 229;
  --ion-color-lightgrey-contrast: #000000;
  --ion-color-lightgrey-contrast-rgb: 0, 0, 0;
  --ion-color-lightgrey-shade: #c6c8ca;
  --ion-color-lightgrey-tint: #e4e6e8;
}

.ion-color-lightgrey {
  --ion-color-base: var(--ion-color-lightgrey);
  --ion-color-base-rgb: var(--ion-color-lightgrey-rgb);
  --ion-color-contrast: var(--ion-color-lightgrey-contrast);
  --ion-color-contrast-rgb: var(--ion-color-lightgrey-contrast-rgb);
  --ion-color-shade: var(--ion-color-lightgrey-shade);
  --ion-color-tint: var(--ion-color-lightgrey-tint);
}

:root {
  --ion-color-offwhite: #f1f2f3;
  --ion-color-offwhite-rgb: 241, 242, 243;
  --ion-color-offwhite-contrast: #000000;
  --ion-color-offwhite-contrast-rgb: 0, 0, 0;
  --ion-color-offwhite-shade: #d4d5d6;
  --ion-color-offwhite-tint: #f2f3f4;
}

.ion-color-offwhite {
  --ion-color-base: var(--ion-color-offwhite);
  --ion-color-base-rgb: var(--ion-color-offwhite-rgb);
  --ion-color-contrast: var(--ion-color-offwhite-contrast);
  --ion-color-contrast-rgb: var(--ion-color-offwhite-contrast-rgb);
  --ion-color-shade: var(--ion-color-offwhite-shade);
  --ion-color-tint: var(--ion-color-offwhite-tint);
}

:root {
  --ion-color-superoffwhite: #f8f9fa;
  --ion-color-superoffwhite-rgb: 248, 249, 250;
  --ion-color-superoffwhite-contrast: #000000;
  --ion-color-superoffwhite-contrast-rgb: 0, 0, 0;
  --ion-color-superoffwhite-shade: #dadbdc;
  --ion-color-superoffwhite-tint: #f9fafb;
}

.ion-color-superoffwhite {
  --ion-color-base: var(--ion-color-superoffwhite);
  --ion-color-base-rgb: var(--ion-color-superoffwhite-rgb);
  --ion-color-contrast: var(--ion-color-superoffwhite-contrast);
  --ion-color-contrast-rgb: var(--ion-color-superoffwhite-contrast-rgb);
  --ion-color-shade: var(--ion-color-superoffwhite-shade);
  --ion-color-tint: var(--ion-color-superoffwhite-tint);
}

:root {
  --ion-color-darkgrey: #25282a;
  --ion-color-darkgrey-rgb: 37, 40, 42;
  --ion-color-darkgrey-contrast: #ffffff;
  --ion-color-darkgrey-contrast-rgb: 255, 255, 255;
  --ion-color-darkgrey-shade: #212325;
  --ion-color-darkgrey-tint: #3b3e3f;
}

.ion-color-darkgrey {
  --ion-color-base: var(--ion-color-darkgrey);
  --ion-color-base-rgb: var(--ion-color-darkgrey-rgb);
  --ion-color-contrast: var(--ion-color-darkgrey-contrast);
  --ion-color-contrast-rgb: var(--ion-color-darkgrey-contrast-rgb);
  --ion-color-shade: var(--ion-color-darkgrey-shade);
  --ion-color-tint: var(--ion-color-darkgrey-tint);
}

:root {
  --ion-color-charcoal: #1d1d1d;
  --ion-color-charcoal-rgb: 29, 29, 29;
  --ion-color-charcoal-contrast: #ffffff;
  --ion-color-charcoal-contrast-rgb: 255, 255, 255;
  --ion-color-charcoal-shade: #1a1a1a;
  --ion-color-charcoal-tint: #343434;
}

.ion-color-charcoal {
  --ion-color-base: var(--ion-color-charcoal);
  --ion-color-base-rgb: var(--ion-color-charcoal-rgb);
  --ion-color-contrast: var(--ion-color-charcoal-contrast);
  --ion-color-contrast-rgb: var(--ion-color-charcoal-contrast-rgb);
  --ion-color-shade: var(--ion-color-charcoal-shade);
  --ion-color-tint: var(--ion-color-charcoal-tint);
}

:root {
  --ion-color-primary: #003c5e;
  --ion-color-primary-rgb: 0, 60, 94;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #003553;
  --ion-color-primary-tint: #1a506e;
}

.ion-color-marine {
  --ion-color-base: var(--ion-color-marine);
  --ion-color-base-rgb: var(--ion-color-marine-rgb);
  --ion-color-contrast: var(--ion-color-marine-contrast);
  --ion-color-contrast-rgb: var(--ion-color-marine-contrast-rgb);
  --ion-color-shade: var(--ion-color-marine-shade);
  --ion-color-tint: var(--ion-color-marine-tint);
}

:root {
  --ion-color-gold: #deac19;
  --ion-color-gold-rgb: 222, 172, 25;
  --ion-color-gold-contrast: #000000;
  --ion-color-gold-contrast-rgb: 0, 0, 0;
  --ion-color-gold-shade: #c39716;
  --ion-color-gold-tint: #e1b430;
}

.ion-color-gold {
  --ion-color-base: var(--ion-color-gold);
  --ion-color-base-rgb: var(--ion-color-gold-rgb);
  --ion-color-contrast: var(--ion-color-gold-contrast);
  --ion-color-contrast-rgb: var(--ion-color-gold-contrast-rgb);
  --ion-color-shade: var(--ion-color-gold-shade);
  --ion-color-tint: var(--ion-color-gold-tint);
}

:root {
  --ion-color-marine: #003c5e;
  --ion-color-marine-rgb: 0, 60, 94;
  --ion-color-marine-contrast: #ffffff;
  --ion-color-marine-contrast-rgb: 255, 255, 255;
  --ion-color-marine-shade: #003553;
  --ion-color-marine-tint: #1a506e;
}

:root {
  --ion-color-darktext: #003c5e;
  --ion-color-darktext-rgb: 0, 60, 94;
  --ion-color-darktext-contrast: #ffffff;
  --ion-color-darktext-contrast-rgb: 255, 255, 255;
  --ion-color-darktext-shade: #003553;
  --ion-color-darktext-tint: #1a506e;
}

.ion-color-darktext {
  --ion-color-base: var(--ion-color-marine);
  --ion-color-base-rgb: var(--ion-color-marine-rgb);
  --ion-color-contrast: var(--ion-color-marine-contrast);
  --ion-color-contrast-rgb: var(--ion-color-marine-contrast-rgb);
  --ion-color-shade: var(--ion-color-marine-shade);
  --ion-color-tint: var(--ion-color-marine-tint);
}

:root {
  --ion-color-vip: #1ac4f8;
  --ion-color-vip-rgb: 26, 196, 248;
  --ion-color-vip-contrast: #000000;
  --ion-color-vip-contrast-rgb: 0, 0, 0;
  --ion-color-vip-shade: #17acda;
  --ion-color-vip-tint: #31caf9;
}

.ion-color-vip {
  --ion-color-base: var(--ion-color-vip);
  --ion-color-base-rgb: var(--ion-color-vip-rgb);
  --ion-color-contrast: var(--ion-color-vip-contrast);
  --ion-color-contrast-rgb: var(--ion-color-vip-contrast-rgb);
  --ion-color-shade: var(--ion-color-vip-shade);
  --ion-color-tint: var(--ion-color-vip-tint);
}

:root {
  --ion-color-facebook: #3b5998;
  --ion-color-facebook-rgb: 59, 89, 152;
  --ion-color-facebook-contrast: #ffffff;
  --ion-color-facebook-contrast-rgb: 255, 255, 255;
  --ion-color-facebook-shade: #344e86;
  --ion-color-facebook-tint: #4f6aa2;
}

.ion-color-facebook {
  --ion-color-base: var(--ion-color-facebook);
  --ion-color-base-rgb: var(--ion-color-facebook-rgb);
  --ion-color-contrast: var(--ion-color-facebook-contrast);
  --ion-color-contrast-rgb: var(--ion-color-facebook-contrast-rgb);
  --ion-color-shade: var(--ion-color-facebook-shade);
  --ion-color-tint: var(--ion-color-facebook-tint);
}

:root {
  --ion-color-vimeo: #23b6ea;
  --ion-color-vimeo-rgb: 35, 182, 234;
  --ion-color-vimeo-contrast: #000000;
  --ion-color-vimeo-contrast-rgb: 0, 0, 0;
  --ion-color-vimeo-shade: #1fa0ce;
  --ion-color-vimeo-tint: #39bdec;
}

.ion-color-vimeo {
  --ion-color-base: var(--ion-color-vimeo);
  --ion-color-base-rgb: var(--ion-color-vimeo-rgb);
  --ion-color-contrast: var(--ion-color-vimeo-contrast);
  --ion-color-contrast-rgb: var(--ion-color-vimeo-contrast-rgb);
  --ion-color-shade: var(--ion-color-vimeo-shade);
  --ion-color-tint: var(--ion-color-vimeo-tint);
}

:root {
  --ion-color-google: #dc4a38;
  --ion-color-google-rgb: 220, 74, 56;
  --ion-color-google-contrast: #ffffff;
  --ion-color-google-contrast-rgb: 255, 255, 255;
  --ion-color-google-shade: #c24131;
  --ion-color-google-tint: #e05c4c;
}

.ion-color-google {
  --ion-color-base: var(--ion-color-google);
  --ion-color-base-rgb: var(--ion-color-google-rgb);
  --ion-color-contrast: var(--ion-color-google-contrast);
  --ion-color-contrast-rgb: var(--ion-color-google-contrast-rgb);
  --ion-color-shade: var(--ion-color-google-shade);
  --ion-color-tint: var(--ion-color-google-tint);
}

:root {
  --ion-color-twitter: #1da1f4;
  --ion-color-twitter-rgb: 29, 161, 244;
  --ion-color-twitter-contrast: #000000;
  --ion-color-twitter-contrast-rgb: 0, 0, 0;
  --ion-color-twitter-shade: #1a8ed7;
  --ion-color-twitter-tint: #34aaf5;
}

.ion-color-twitter {
  --ion-color-base: var(--ion-color-twitter);
  --ion-color-base-rgb: var(--ion-color-twitter-rgb);
  --ion-color-contrast: var(--ion-color-twitter-contrast);
  --ion-color-contrast-rgb: var(--ion-color-twitter-contrast-rgb);
  --ion-color-shade: var(--ion-color-twitter-shade);
  --ion-color-tint: var(--ion-color-twitter-tint);
}

:root {
  --ion-color-favorite: #69bb7b;
  --ion-color-favorite-rgb: 105, 187, 123;
  --ion-color-favorite-contrast: #000000;
  --ion-color-favorite-contrast-rgb: 0, 0, 0;
  --ion-color-favorite-shade: #5ca56c;
  --ion-color-favorite-tint: #78c288;
}

.ion-color-favorite {
  --ion-color-base: var(--ion-color-favorite);
  --ion-color-base-rgb: var(--ion-color-favorite-rgb);
  --ion-color-contrast: var(--ion-color-favorite-contrast);
  --ion-color-contrast-rgb: var(--ion-color-favorite-contrast-rgb);
  --ion-color-shade: var(--ion-color-favorite-shade);
  --ion-color-tint: var(--ion-color-favorite-tint);
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}
